<template>
  <v-card flat :min-height="`${$vuetify.breakpoint.height - 120}px`">
    <v-card-text class="pa-0 pt-1 text-center">
      <div v-if="disabled" id="coming-soon">Coming soon...</div>
      <div v-if="!disabled" ref="video" id="presentation-video"></div>
    </v-card-text>
  </v-card>
</template>

<script>
import Player from "@vimeo/player";

export default {
  name: "VideoPlayer",
  props: {
    visible: Boolean,
    name: String,
    link: String,
    disabled: Boolean,
  },
  data() {
    return {
      viewerOptions: {
        inline: false,
        button: true,
        navbar: false,
        title: false,
        toolbar: false,
        tooltip: false,
        movable: true,
        zoomable: true,
        rotatable: false,
        scalable: false,
        transition: true,
        fullscreen: true,
        keyboard: false,
        url: "data-src",
      },
      presentationPlayer: null,
    };
  },
  watch: {
    visible() {
      if (!this.visible) {
        this.presentationPlayer.pause();
      } else {
        this.track();
      }
    },
  },
  methods: {
    track() {
      this.$emit("view-page", this.name);
    },
  },
  mounted() {
    // heightOffset = tab height + toolbar height + padding
    const heightOffset = 48 + 64 + 10;
    const options = {
      url: this.link,
      color: "#852166",
      height: window.innerHeight - heightOffset,
    };
    this.presentationPlayer = new Player("presentation-video", options);
    this.presentationPlayer.on("play", () => {
      if (this.$gtag) {
        this.$gtag.event("VIDEO_PLAY", {
          event_category: "Video",
          event_label: this.name,
        });
      }
    });

    this.presentationPlayer.on("pause", () => {
      if (this.$gtag) {
        this.$gtag.event("VIDEO_PAUSE", {
          event_category: "Video",
          event_label: this.name,
        });
      }
    });

    this.presentationPlayer.on("ended", () => {
      if (this.$gtag) {
        this.$gtag.event("VIDEO_ENDED", {
          event_category: "Video",
          event_label: this.name,
        });
      }
    });

    this.track();
  },
};
</script>

<style>
#presentation-video iframe {
  max-width: 100vw;
}
#coming-soon {
  margin-top: 20%;
  font-weight: bold;
  font-size: 200%;
}
</style>